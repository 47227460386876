import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa';
import map from '../imgs/map.png'
import dente from '../imgs/dente.png'
import '../App.css'
import {useNavigate} from 'react-router-dom';

const FisrtPage = () => {
    const [cap, setCap] = useState("");
    const navigate = useNavigate();
    const searchDentista = () => {
        if (cap === ""){
            window.alert("Inserisci il cap")
            return
        }
        navigate('/loading', {state: { cap: cap }})
    }
  return (
    <div className='first-page'>
        <h1>Troviamo il <i>miglior dentista vicino a te!</i></h1>
        <h2 style={{margin: '10px 0'}}>Per i tuoi impianti dentali</h2>
        <p>Inserisci il tuo CAP</p>
        <div className='cap-input'>
            <FaSearch />
            <input value={cap} onChange={(e) => setCap(e.target.value)} type='text' placeholder='00191' />
        </div>
        <button onClick={searchDentista}>Avvia ricerca</button>
        <img alt='map dentista' src={map} />
        <p>Risultati migliori vicino a te</p>
        <img className='dente' alt='dente' src={dente} />
    </div>
  )
}

export default FisrtPage