import React, {useState} from 'react'
import rev1 from '../imgs/review1.png'
import rev2 from '../imgs/review2.png';
import rev3 from '../imgs/review3.png';
import resultImg from '../imgs/resultimg.png'
import {useLocation, useNavigate} from 'react-router-dom'

const Results4 = () => {
  const [cellulare, setCellulare] = useState("");
  const location = useLocation();
  const [ld, setLd] = useState("4");
  const navigate = useNavigate();
  const { state } = location;
  const cap = state && state.cap ? state.cap : '';
  
  const sendSheet = async () => {
    const dataToSend = {
      Data: new Date(),
      Cap: cap,
      Cellulare: "Rimando su whatsapp",
      Landing: ld,
    };
    console.log(dataToSend);
    const sheetUrl = 'https://sheet.best/api/sheets/c427a243-f4d1-4502-bcb7-9b582a80f751';
    
    await fetch(sheetUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    })
      .then(response => {
        console.log('Dati inviati con successo:', response);
        navigate('/thanks');
      })
      .catch(error => {
        console.error('Si è verificato un errore durante l\'invio dei dati:', error);
      });
  }
  return (
    <div className='results'>
        <h1>Ecco cosa <br /> abbiamo trovato!</h1>
        <hr />
        <p><b>1 di 25</b> risultati trovati</p>
        <button style={{margin: '5px 0 35px 0', backgroundColor: '#12E100', width: '60%'}} onClick={sendSheet}>Chiedi informazioni</button>
        <img className='resultImg' alt='img' src={resultImg} />
        <div className='recensioni-header'>
            <h2>Recensioni</h2>
            <p>45 recensioni</p>
        </div>
        <div className='recensioni-body'>
            <img alt='recensione 1' src={rev1} />
            <img alt='recensione 2' src={rev2} />
            <img alt='recensione 3' src={rev3} />
        </div>
    </div>
  )
}

export default Results4