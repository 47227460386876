import React, {useState} from 'react'
import star from '../imgs/star.png';
import medico from '../imgs/medico.png';
import rev1 from '../imgs/review1.png'
import rev2 from '../imgs/review2.png';
import rev3 from '../imgs/review3.png';
import {useLocation, useNavigate} from 'react-router-dom'

const Results3 = () => {
    const [cellulare, setCellulare] = useState("");
    const [nomeCompleto, setNomeCompleto] = useState("");
    const location = useLocation();
    const [ld, setLd] = useState("3");
    const navigate = useNavigate();
    const { state } = location;
    const cap = state && state.cap ? state.cap : '';
    console.log(cap)
    
    const sendSheet = async () => {
      if (cellulare === ""){
        window.alert("Inserisci il cellulare");
        return
      }
      const regex = /^[0-9]{10}$/;
      if (!regex.test(cellulare)) {
          window.alert("Inserisci un cellulare valido");
          return;
      }
      //Meta web G
      //Implantologia a carico immediato
      const capString = String(cap);
      const dataToSend = {
        Data: new Date(),
        Cap: capString,
        Cellulare: cellulare,
        Nome: nomeCompleto,
        Landing: ld,
      };
      //https://leadsystembluedental-production.up.railway.app/lead-cap
      const dataToSend2 = {
        capString,
        cellulare,
        nomeCompleto,
      }
      console.log(dataToSend);
      const sheetUrl = 'https://sheet.best/api/sheets/c427a243-f4d1-4502-bcb7-9b582a80f751';
      const serverUrl = 'https://leadsystembluedental-production.up.railway.app/api/lead-cap';
      await fetch(serverUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend2),
      })
        .then(async(response) => {
          console.log('Dati inviati con successo:', response);
          navigate('/thanks');
          await fetch(sheetUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
          })
        })
        .catch(error => {
          console.error('Si è verificato un errore durante l\'invio dei dati:', error);
        });
    }
  return (
    <div className='results'>
        <h1>Ecco cosa <br /> abbiamo trovato!</h1>
        <hr />
        <p>Dentista a <b>2Km</b> di distanza da te.</p>
        <div className='div1'>
            <p><u>Fascia di prezzo:</u> <span>Economica</span></p>
            <img src={star} alt='star'/>
        </div>
        <div className='div2'>
            <p><b>1 di 25</b> risultati trovati </p>
            <p>4.5/5 Valutazione</p>
        </div>
        <img alt='medico' src={medico} />
        <p style={{marginTop: '20px'}}>Inserisci il tuo numero</p>
        <input style={{marginTop: '0px', width: '60%'}} placeholder='Numero Telefono' type='text' value={cellulare} onChange={(e) => setCellulare(e.target.value)} />
        <p style={{marginTop: '0px'}}>Inserisci il tuo nome e cognome</p>
        <input style={{marginTop: '0px', width: '60%'}} placeholder='Nome completo' type='text' value={nomeCompleto} onChange={(e) => setNomeCompleto(e.target.value)} />
        <button onClick={sendSheet}>Fammi richiamare gratis</button>
        <div className='recensioni-header'>
            <h2>Recensioni</h2>
            <p>45 recensioni</p>
        </div>
        <div className='recensioni-body'>
            <img alt='recensione 1' src={rev1} />
            <img alt='recensione 2' src={rev2} />
            <img alt='recensione 3' src={rev3} />
        </div>
    </div>
  )
}

export default Results3