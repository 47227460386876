import React, { useState } from 'react'
import medicoBlur from '../imgs/medicoblur.png'
import doppioDente from '../imgs/doppiodente.png'
import axios from 'axios'
import {useLocation, useNavigate} from 'react-router-dom'

const Results1 = () => {
  const [cellulare, setCellulare] = useState("");
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [ld, setLd] = useState("1");
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const cap = state && state.cap ? state.cap : '';
  const randomPage = state && state.randomPage ? state.randomPage : '';
  console.log(cap)
  
  const sendSheet = async () => {
    if (cellulare === ""){
      window.alert("Inserisci il cellulare");
      return
    }
    const regex = /^[0-9]{10}$/;
    if (!regex.test(cellulare)) {
        window.alert("Inserisci un cellulare valido");
        return;
    }
    //Meta web G
    //Implantologia a carico immediato
    const capString = String(cap);
    const dataToSend = {
      Data: new Date(),
      Cap: capString,
      Cellulare: cellulare,
      Nome: nomeCompleto,
      Landing: ld,
    };
    //https://leadsystembluedental-production.up.railway.app/lead-cap
    const dataToSend2 = {
      capString,
      cellulare,
      nomeCompleto,
    }
    console.log(dataToSend);
    const sheetUrl = 'https://sheet.best/api/sheets/c427a243-f4d1-4502-bcb7-9b582a80f751';
    const serverUrl = 'https://leadsystembluedental-production.up.railway.app/api/lead-cap';
    await fetch(serverUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend2),
    })
      .then(async(response) => {
        console.log('Dati inviati con successo:', response);
        navigate('/thanks');
        await fetch(sheetUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        })
      })
      .catch(error => {
        console.error('Si è verificato un errore durante l\'invio dei dati:', error);
      });
  }
  return (
    <div className='results'>
        <h1>Ecco cosa <br /> abbiamo trovato!</h1>
        <hr />
        <p><b>1 di 25</b> risultati trovati</p>
        <img alt='medico blur' src={medicoBlur} />
        <div className='results-bottom'>
            <img alt='doppio dente' src={doppioDente} />
            <h2>Abbiamo trovato <br />il <b>dentista per te</b></h2>
            <p>Confrontando prezzi e recensioni abbiamo individuato il miglior dentista rapporto <b>qualità prezzo</b></p>
            <p style={{width: '70%', margin: '0 auto', marginTop: '30px'}}>Inserisci il tuo numero e effettua la tua <b>prima visita gratuita</b></p>
            <input style={{width: '60%'}} placeholder='Numero Telefono' type='text' value={cellulare} onChange={(e) => setCellulare(e.target.value)} />
            <p style={{marginTop: '0px'}}>Inserisci il tuo nome e cognome</p>
            <input style={{marginTop: '0px', width: '60%'}} placeholder='Nome completo' type='text' value={nomeCompleto} onChange={(e) => setNomeCompleto(e.target.value)} />
            <button onClick={sendSheet}>Fammi richiamare gratis</button>
        </div>
    </div>
  )
}

export default Results1