import React from 'react'

const Thanks = () => {
  return (
    <div className='first-page'style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <h2 style={{width: '80%'}}>Grazie, sarai ricontattato da un nostro operatore</h2>
    </div>
  )
}

export default Thanks