import React, {useState} from 'react'
import rev1 from '../imgs/review1.png'
import rev2 from '../imgs/review2.png';
import rev3 from '../imgs/review3.png';
import resultImg from '../imgs/resultimg.png'
import {useLocation, useNavigate} from 'react-router-dom'

const Results2 = () => {
  const [cellulare, setCellulare] = useState("");
  const [nomeCompleto, setNomeCompleto] = useState("");
  const location = useLocation();
  const [ld, setLd] = useState("2");
  const navigate = useNavigate();
  const { state } = location;
  const cap = state && state.cap ? state.cap : '';
  console.log(cap)
  
  const sendSheet = async () => {
    if (cellulare === ""){
      window.alert("Inserisci il cellulare");
      return
    }
    const regex = /^[0-9]{10}$/;
    if (!regex.test(cellulare)) {
        window.alert("Inserisci un cellulare valido");
        return;
    }
    //Meta web G
    //Implantologia a carico immediato
    const capString = String(cap);
    const dataToSend = {
      Data: new Date(),
      Cap: capString,
      Cellulare: cellulare,
      Nome: nomeCompleto,
      Landing: ld,
    };
    //https://leadsystembluedental-production.up.railway.app/lead-cap
    const dataToSend2 = {
      capString,
      cellulare,
      nomeCompleto,
    }
    console.log(dataToSend);
    const sheetUrl = 'https://sheet.best/api/sheets/c427a243-f4d1-4502-bcb7-9b582a80f751';
    const serverUrl = 'https://leadsystembluedental-production.up.railway.app/api/lead-cap';
    await fetch(serverUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend2),
    })
      .then(async(response) => {
        console.log('Dati inviati con successo:', response);
        navigate('/thanks');
        await fetch(sheetUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        })
      })
      .catch(error => {
        console.error('Si è verificato un errore durante l\'invio dei dati:', error);
      });
  }
  return (
    <div className='results'>
        <h1>Ecco cosa <br /> abbiamo trovato!</h1>
        <hr />
        <p><b>1 di 25</b> risultati trovati</p>
        <p style={{marginTop: '0px'}}>Inserisci il tuo numero</p>
        <input style={{marginTop: '0px', width: '60%'}} placeholder='Numero Telefono' type='text' value={cellulare} onChange={(e) => setCellulare(e.target.value)} />
        <p style={{marginTop: '0px'}}>Inserisci il tuo nome e cognome</p>
        <input style={{marginTop: '0px', width: '60%'}} placeholder='Nome completo' type='text' value={nomeCompleto} onChange={(e) => setNomeCompleto(e.target.value)} />
        <button style={{margin: '5px 0 35px 0'}} onClick={sendSheet}>Fammi richiamare gratis</button>
        <img className='resultImg' alt='img' src={resultImg} />
        <div className='recensioni-header'>
            <h2>Recensioni</h2>
            <p>45 recensioni</p>
        </div>
        <div className='recensioni-body'>
            <img alt='recensione 1' src={rev1} />
            <img alt='recensione 2' src={rev2} />
            <img alt='recensione 3' src={rev3} />
        </div>
    </div>
  )
}

export default Results2