import React, {Suspense} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import FisrtPage from "./pages/FisrtPage";
import Results1 from "./pages/Results1";
import Results2 from "./pages/Results2";
import Results3 from "./pages/Results3";
import LoadingPage from './pages/LoadingPage';
import Thanks from "./pages/Thanks";
import Results4 from "./pages/Results4";

function App() {
  return (
    <Router>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 3000,
        }}
      />
        <Routes>
          <Route
            exact
            path="/"
            element={<FisrtPage />}
          />
          <Route
            exact
            path="/result1"
            element={<Results1 />}
          />
          <Route
            exact
            path="/result2"
            element={<Results2 />}
          />
          <Route
            exact
            path="/result4"
            element={<Results4 />}
          />
          <Route
            exact
            path="/result3"
            element={<Results3 />}
          />
          <Route
            exact
            path="/loading"
            element={<LoadingPage />}
          />
          <Route
            exact
            path="/thanks"
            element={<Thanks />}
          />
        </Routes>
    </Router>
  );
}

export default App;
