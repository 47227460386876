import React, { useEffect, useState } from 'react';
import {useNavigate, useLocation} from 'react-router-dom'

function LoadingPage() {
  const [progress, setProgress] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const navigate = useNavigate()
  const location = useLocation();
  const { state } = location;
  const cap = state && state.cap ? state.cap : '';
  const texts = ['Ricerco dentisti in zona', 'Analizzo i prezzi migliori', 'Identifico le migliori recensioni'];

  useEffect(() => {
    const duration = 10000; // Durata totale del caricamento in millisecondi (10 secondi)
    const textDuration = 3300; // Durata totale per il cambio di testo in millisecondi (3.3 secondi)
    const steps = 100; // Numero di passaggi per completare il caricamento

    let startTime = null;

    const updateProgress = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;
      const progress = Math.min((elapsedTime / duration) * 100, 100);
      setProgress(progress);

      if (progress < 100) {
        requestAnimationFrame(updateProgress);
      }
    };

    const updateTextIndex = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;
      const textIndex = Math.floor((elapsedTime / textDuration) % texts.length);
      setTextIndex(textIndex);

      if (progress < 100) {
        requestAnimationFrame(updateTextIndex);
      }
    };

    requestAnimationFrame(updateProgress);
    requestAnimationFrame(updateTextIndex);

    const getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    
    const randomPage = getRandomInt(1, 3);
    
    let nextPage;
    
    switch (randomPage) {
      case 1:
        nextPage = '/result1';
        break;
      case 2:
        nextPage = '/result2';
        break;
      case 3:
        nextPage = '/result3';
        break;
      /*case 4:
        nextPage = '/result4';
        break;*/
      default:
        nextPage = '/result1';
        break;
    }
    
    const timeoutId = setTimeout(() => {
      navigate(nextPage, { state: { cap: cap, randomPage: randomPage } }); 
    }, duration);

    // Pulizia dell'effetto quando il componente viene smontato o quando il caricamento è completato
    return () => {
      clearTimeout(timeoutId);
    };
  }, [navigate, texts.length]); 

  return (
    <div className="loading-page">
      <div className="spinner"></div>
      <div className="loading-text">{texts[textIndex]}</div>
      <div className="loader">
        <div className="progress" style={{ width: `${progress}%`, background: 'linear-gradient(to right, #213846, #07476D)' }}></div>
      </div>
    </div>
  );
}

export default LoadingPage;